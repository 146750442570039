




















































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import PengajuanValidateFM from "../models/pengajuanForm";
import { pengajuanState } from "../modules/stores/pengajuan";
import useFormModel from "@/apps/core/modules/useFormModel";
import { SaveContext } from "@/apps/core/models/formModel";
import router from "@/router";

export default defineComponent({
  name: "AjukanPinjamanForm",
  components: {
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const requiredFields = ["nilai", "tenor"];
    const productId = router.currentRoute.params.id;
    const form = new PengajuanValidateFM(productId, requiredFields, ["angsuran"]);
    const composition = useFormModel(form);
    const bgImageUrl = require("@/apps/pinjaman/assets/img/pengajuan_bg.png");
    const produkName = pengajuanState.produkName;

    if (pengajuanState.pengajuanFM) {
      // User sudah mengisi form pengajuan
      Object.assign(form.instance, pengajuanState.pengajuanFM.instance);
      form.resetErrorMap(true);
      form.validity.edited = true;
      form.validity.validated = true;
    }

    const validateNext = async () => {
      await form.save(SaveContext.Create, false, false);
      if (composition.isValid.value) {
        pengajuanState.produk = productId;
        pengajuanState.pengajuanFM = reactive(form);
        router.push({ name: "pengajuan-berkas" });
      }
    };

    const formValid = computed(
      () => composition.isValid.value && pengajuanState.agree
    );
    return {
      // Data
      bgImageUrl,
      pengajuanState,
      formValid,
      produkName,

      // Composition
      ...composition,

      // Method
      validateNext,
    };
  },
});
