











































import { defineComponent } from "@vue/composition-api";
import useInfiniteScrollList from "../modules/useInfiniteScrollList";
import { isNasabah, isPengelola } from "@/apps/accounts/modules/store";
import router from "@/router";
import { listVM } from "@/apps/pinjaman/modules/stores/cicilan";

export default defineComponent({
  name: "RiwayatAngsuranDetail",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    CicilanListBox: () => import("@/apps/pinjaman/components/CicilanListBox.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const composition = useInfiniteScrollList(listVM);
    composition.filterMap.pinjaman = router.currentRoute.params.id;
    const nasabahId = router.currentRoute.params.nasabahId;
    if (isPengelola.value && nasabahId) {
      composition.filterMap.nasabah = nasabahId;
    }

    const classContent = isNasabah.value? "column no-padding is-full":"column is-full";
    return {
      // Data
      classContent,

      // Composition
      ...composition,
    };
  },
});
