





























































































































import { defineComponent, ref, reactive, Ref, watch } from "@vue/composition-api";
import { listVM } from "../modules/stores/pengajuan";
import PengajuanValidateFM from "../models/pengajuanForm";
import { PengajuanFM } from "../models/pengajuanForm";
import useFormModel from "@/apps/core/modules/useFormModel";
import { SaveContext } from "@/apps/core/models/formModel";
import router from "@/router";
import { Pengajuan } from "../models/pengajuan";
import { formatRupiah } from "@/apps/core/modules/utils/text";
import { Pinjaman } from "../models/pinjaman";
import Vue from "vue";

export default defineComponent({
  name: "PerubahanPinjamanForm",
  props: { tipe: { type: String }},
  components: {
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup(props) {
    const requiredFields = ["nilai", "tenor"];
    const form = new PengajuanFM(null, requiredFields, ["angsuran", "tipe"]);
    const newPVForm = new PengajuanValidateFM("", requiredFields, ["angsuran"]);
    const newErrorMap = reactive(newPVForm.errorMap);
    const pengajuanId = router.currentRoute.params.id;
    const composition = useFormModel(form, pengajuanId);
    const bgImageUrl = require("@/apps/pinjaman/assets/img/pengajuan_bg.png");
    const cancel = () => router.go(-1);
    const agree = ref(false);
    const angsuran = ref(0) as Ref<number|null>;
    let isInstanceLoaded = false;

    console.log(props.tipe);
    if (!props.tipe) {
      router.go(-1);
    }

    watch(composition.instance, () => {
      // console.log(composition.instance.id);
      if (!isInstanceLoaded) {
        Vue.nextTick().then(() => {
          const pengajuan = form.instance as Pengajuan;
          if (props.tipe === 'ubah-tenor') {
            const pinjaman = pengajuan.pinjaman as Pinjaman;
            if (pinjaman) {
              pengajuan.nilai = pinjaman.sisa;
              const tenor = pinjaman.tenor ?? 0;
              const bayarCount = pinjaman.bayarCount ?? 0;
              pengajuan.tenor = tenor - bayarCount + 1;
            }
          }
          pengajuan.tipe = props.tipe ?? "";
          form.setEdited(true);
        });
        isInstanceLoaded = true;
      }
    });

    const validateNext = async () => {
      newPVForm.resetErrorMap();
      const instance = form.instance as Pengajuan;
      // console.log(instance);
      const prodId = instance.produk?.id;
      // console.log(prodId);
      newPVForm.productId = prodId;
      const vldInstance = newPVForm.instance as Pengajuan;
      vldInstance.nilai = instance.nilai;
      vldInstance.tenor = instance.tenor;
      vldInstance.prevPinjaman = instance.pinjaman?.id ?? null;
      vldInstance.tipe = props.tipe ?? "";
      await newPVForm.save(SaveContext.Create, false, false);
      // console.log(newPVForm.errorMap);
      if (form.getIsValid().value) {
        angsuran.value = vldInstance.angsuran;
        if (instance.nilai !== vldInstance.nilai) {
          instance.nilai = vldInstance.nilai;
        }
        // console.log(vldInstance);
      }
    };

    const saveForm = async () => {
      form.resetErrorMap();
      const instance = form.instance as Pengajuan;
      const newForm = new PengajuanFM(null, requiredFields, ["angsuran", "tipe"]);
      const newInstance = newForm.instance as Pengajuan;
      newInstance.produk = {id: instance.produk?.id};
      newInstance.nilai = instance.nilai;
      newInstance.tenor = instance.tenor;
      newInstance.prevPinjaman = instance.pinjaman?.id ?? null;
      newInstance.tipe = instance.tipe;
      // const pengajuan = form.instance as Pengajuan;
      await newForm.save(SaveContext.Create, false);
      if (form.getIsValid().value) {
        listVM.setRefreshRequest("pengajuan");
        router.push({name: "pinjaman-list"});
      } else {
        Object.assign(form.errorMap, newForm.errorMap);
      }
    };

    return {
      // Data
      agree,
      angsuran,
      bgImageUrl,
      newErrorMap,

      // Composition
      ...composition,

      // Method
      cancel,
      formatRupiah,
      saveForm,
      validateNext,
    };
  },
});
