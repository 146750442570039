


























































































































































































































































































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { isNasabah, isPengelola } from "@/apps/accounts/modules/store";
import { Pengajuan } from "../models/pengajuan";
import { formatRupiah } from "@/apps/core/modules/utils/text";
import {
  toUserDateFormat,
  toUserDateTimeFormat,
} from "@/apps/core/modules/utils/datetime";
import router from "@/router";
import { PengajuanFM } from "../models/pengajuanForm";
import useFormModel from "@/apps/core/modules/useFormModel";
import PenutupanFM from "../models/penutupanForm";
import { Pinjaman } from "../models/pinjaman";
import { listVM } from "../modules/stores/pengajuan";
import { Penutupan } from "../models/penutupan";
import { SaveContext } from "@/apps/core/models/formModel";
import axios from "axios";
import APP_CONFIG from "@/apps/core/modules/config";
import UploadPhoto from "@/apps/core/modules/uploadPhoto";
import {
  DialogProgrammatic as Dialog,
  ToastProgrammatic as Toast,
} from "buefy";
// import { setRefreshRequest } from "@/apps/pinjaman/modules/stores/cicilan";
// import { DialogProgrammatic as Dialog } from "buefy";

export default defineComponent({
  name: "PengajuanDetail",
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    DetailField: () => import("@/apps/core/components/DetailField.vue"),
    StatusTag: () => import("@/apps/core/components/StatusTag.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const form = new PengajuanFM(null, ["pinjaman", "status"], ["catatan"]);
    const pengajuanId = router.currentRoute.params.id;
    const composition = useFormModel(form, pengajuanId);
    const instance = composition.instance;
    const pengajuan = instance as Pengajuan;
    const nilaiPenutupan = ref(0);
    const nilaiDiterima = ref(0);
    let isInstanceLoaded = false;
    const tipeStr = ref("");

    const pentupanForm = new PenutupanFM(["bunga", "totalBayar"], ["catatan"]);
    const penutupanComposition = useFormModel(pentupanForm);

    const isUbahMenuActive = ref(false);

    const tutupPinjaman = async () => {
      const instance = penutupanComposition.instance;
      const penutupan = instance as Penutupan;
      penutupan.pinjaman = pengajuan?.pinjaman?.id;
      pentupanForm.save();
    };

    const confirmPenutupan = () => {
      Dialog.confirm({
        message: "Anda yakin akan melakukan pelunasan pinjaman ini?",
        confirmText: "Ya",
        cancelText: "Tidak",
        onConfirm: () => tutupPinjaman(),
      });
    };

    const action = reactive({
      type: null,
      routeName: null,
      callBack: null,
    } as Record<string, any>);

    if (isNasabah.value) {
      Object.assign(action, {
        type: "add",
        routeName: "syarat-ketentuan-pengajuan",
        callBack: null,
      });
    }
    const bgImageUrl = require("@/apps/pinjaman/assets/img/pengajuan_bg.png");

    const downloadFile = (url: string) => window.open(url, "_blank");

    const getFileIcon = (url: string) => {
      const re = /(?:\.([^.]+))?$/ as RegExp;
      const ext = re.exec(url);
      if (ext === null) return require("@/apps/core/assets/img/xlsx.png");
      return require(`@/apps/core/assets/img/${ext[1]}.png`);
    };

    const classContent = isNasabah.value
      ? "column no-padding is-full"
      : "column is-full";

    const saveForm = async () => {
      await composition.formRef.save(SaveContext.Update, false);
      if (form.getIsValid().value) {
        listVM.setRefreshRequest("pengajuan-detail");
        composition.formRef.isLoading = true;
        await composition.formRef.fetch(pengajuanId);
        composition.formRef.isLoading = false;
      }
    };

    const confirmSave = () => {
      Dialog.confirm({
        message: "Anda yakin dengan perubahan ini?",
        confirmText: "Ya",
        cancelText: "Tidak",
        onConfirm: () => saveForm(),
      });
    };

    const pijat = computed(
      () =>
        pengajuan.produk && pengajuan.produk.nama === "PINJAMAN DARURAT (PIJAT)"
    );

    const isCompleted = computed(
      () =>
        isPengelola.value &&
        (pengajuan.status === "Tolak" ||
          (pengajuan.pinjaman && pengajuan.pinjaman.status === "Selesai"))
    );

    const showAngsuran = computed(
      () =>
        isPengelola.value &&
        pijat.value &&
        pengajuan.approval &&
        pengajuan.status === "Setuju"
    );

    watch(pengajuan, () => {
      if (pengajuan && !isInstanceLoaded) {
        const objId = pengajuan.id;
        const apiUrl = `${APP_CONFIG.baseAPIURL}/pinjaman/pengajuan/${objId}/hitung-perubahan/`;
        // console.log(apiUrl)
        axios.get(apiUrl)
          .then((resp) => {
            // console.log(resp.data);
            if (resp.data.ubah) {
              nilaiPenutupan.value = resp.data.nilai;
              nilaiDiterima.value = (pengajuan.nilai ?? 0) - nilaiPenutupan.value;
              if (pengajuan.tipe === "topup") {
                tipeStr.value = " (TOP UP)";
              } else if (pengajuan.tipe === 'ubah-tenor') {
                tipeStr.value = " (UBAH TENOR)";
              }
            }
          })
          .catch(() => {
            /* do nothing */
          });
        isInstanceLoaded = true;
      }

      if (pengajuan.status !== "Tolak" && pengajuan.pinjaman === null) {
        const pinjaman = reactive(new Pinjaman()) as Pinjaman;
        pinjaman.pengajuan = pengajuan.id;
        pinjaman.tenor = pengajuan.tenor;
        pinjaman.bunga = pengajuan.produk?.bunga;
        pinjaman.nilaiPinjaman = pengajuan.nilai;
        pinjaman.angsuran = {
          "Angsuran 1": {
            num: 1,
            pokok: 0,
            bunga: 0,
            cicilan: 0,
            editable: true,
          },
          "Angsuran 2": {
            num: 2,
            pokok: 0,
            denda: 0,
            bunga: 0,
            cicilan: 0,
            editable: true,
          },
          "Angsuran 3": {
            num: 3,
            pokok: 0,
            denda: 0,
            bunga: 0,
            cicilan: 0,
            editable: true,
          },
        };
        pengajuan.setPinjaman(pinjaman as Pinjaman);
      } else if (
        pengajuan.approval !== null &&
        pijat.value &&
        pengajuan.pinjaman &&
        typeof pengajuan.pinjaman.angsuran === "string"
      ) {
        pengajuan.pinjaman.angsuran = JSON.parse(
          (pengajuan.pinjaman.angsuran as string) ?? "{}"
        );
      }
    });

    const penutupan = penutupanComposition.instance as Penutupan;
    const hitungPenutupan = async () => {
      const apiUrl = `${APP_CONFIG.baseAPIURL}/pinjaman/${pengajuan.pinjaman?.id}/cek-pelunasan/`;
      const response = await axios.get(apiUrl);
      penutupan.totalBayar = response.data.total_bayar;
      penutupan.bunga = response.data.bunga;
      penutupanComposition.validate("bunga");
      penutupanComposition.validate("totalBayar");
    };

    const goToRiwayat = () => {
      const pinjamanId = pengajuan.pinjaman?.id ?? "";
      if (isPengelola.value) {
        router.push({
          name: "pengelola-pinjaman-cicilan",
          params: { id: pinjamanId, nasabahId: pengajuan.nasabah?.id ?? "" },
        });
      } else {
        router.push({
          name: "pinjaman-cicilan",
          params: { id: pinjamanId },
        });
      }
      // setRefreshRequest("pengajuan-list");
    };

    const isUploading = ref(false);
    const uploadFile = new UploadPhoto("pengajuan", (uploading: boolean) => {
      isUploading.value = uploading;
    });

    const berkasRef = ref(null);
    const uploadBerkas = () => {
      uploadFile.requestAWS(
        berkasRef.value,
        async (url: string | null, isImage: boolean | null) => {
          const uploadDocUrl = `${APP_CONFIG.baseAPIURL}/pinjaman/berkas/`;
          const data = {
            pengajuan: pengajuan.id,
            berkas: url,
            is_image: isImage,
          };
          try {
            const response = await axios.post(uploadDocUrl, data);
            if (pengajuan.dokList === null) {
              pengajuan.dokList = [];
            }
            const dok = {
              id: response.data.id,
              berkas: url,
              isImage: isImage,
            } as Record<string, any>;
            pengajuan.dokList.push(dok);
            berkasRef.value = null;
            Toast.open("Foto berhasil di upload.");
          } catch {
            Toast.open("Foto gagal di upload.");
          }
        }
      );
    };

    const canChangePinjaman = computed(() => {
      const notPijat = pengajuan?.produk?.nama !== "PINJAMAN DARURAT (PIJAT)";
      const isActive = pengajuan?.pinjaman?.status === "Berjalan";
      const totalBayar = pengajuan?.pinjaman?.totalBayar || 0;
      const totalPinjaman = pengajuan?.pinjaman?.totalPinjaman || 0;
      const passPct =
        notPijat && totalBayar > 0 && totalBayar >= totalPinjaman / 2;
      // console.log(totalBayar);
      // console.log(totalPinjaman);
      // console.log(passPct);
      return isNasabah.value && isActive && passPct;
    });

    // const topUpUrl = router.resolve({name: "pinjaman-ubah", params: {tipe: "topup"}});
    // console.log(topUpUrl);
    // const ubahTenorUrl = router.resolve({name: "pinjaman-ubah", params: {tipe: "ubah-tenor"}});

    return {
      // Data
      action,
      bgImageUrl,
      classContent,
      isCompleted,
      isUbahMenuActive,
      nilaiPenutupan,
      nilaiDiterima,
      penutupan: penutupan,
      pijat,
      showAngsuran,
      tipeStr,
      // pinjaman,

      // Composition
      berkasRef,
      ...composition,
      penutupanComposition,

      // Computed
      canChangePinjaman,
      isNasabah,
      isUploading,
      isPengelola,
      pengajuan,

      // Method
      downloadFile,
      formatRupiah,
      getFileIcon,
      goToRiwayat,
      hitungPenutupan,
      confirmSave,
      confirmPenutupan,
      toUserDateFormat,
      toUserDateTimeFormat,
      uploadBerkas,
    };
  },
});
