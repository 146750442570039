import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";
import { Nasabah } from "@/apps/nasabah/models/nasabah";


class Pinjaman extends PlainModel {
  pengajuan: string | null = null;
  nasabah: Nasabah | null = null;
  mulai: string | null = null;
  produk: Record<string, string> | null = null;
  nilaiPinjaman: number | null = null;
  nilaiCicilan: number | null = null;
  angsuran: Record<string, any> | string | null = null;
  tenor: number | null = null;
  bunga: number | null = null;
  sisa: number | null = null;
  bayarCount: number | null = null;
  totalBayar: number | null = null;
  totalPinjaman: number | null = null;
  status: string | null = null;
  estimasiSelesai: string | null = null;
  selesai: string | null = null;
  catatan: string | null = null;
}

class PinjamanAPI extends API {
  static modelPath = "/pinjaman/";

  constructor() {
    super(PinjamanAPI.modelPath);
  }
}

export default class PinjamanVM extends ViewModel {
  constructor() {
    super(new PinjamanAPI(), new Pinjaman());
  }
}


export { Pinjaman, PinjamanAPI };