import { API } from "@/apps/core/api";
import PlainModel from "@/apps/core/models/plainModel";


class Penutupan extends PlainModel {
  pinjaman: string | null | undefined = null;
  pengelolaNama: string | null = null;
  created: string | null = null;
  bunga: number | null = null;
  totalBiaya: number | null = null;
  totalBayar: number | null = null;
  catatan: string | null = null;
}

class PenutupanAPI extends API {
  static modelPath = "/pinjaman/penutupan/";

  constructor() {
    super(PenutupanAPI.modelPath);
  }
}


export { Penutupan, PenutupanAPI };