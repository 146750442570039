import { onActivated, onDeactivated } from "@vue/composition-api";
import ViewListModel from "../models/viewListModel";
import useListViewModel from "./useListViewModel";

const isInViewport = (element: Element, topOffset = 0) => {
  const rect = element.getBoundingClientRect();
  if (topOffset > 0) {
    // to check the first list element
    return (
      rect.bottom >= 0 + topOffset &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  }
  return (
    // to check the last list element
    rect.top >= 0 + topOffset &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight)
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const useInfiniteScrollList = (listVM: ViewListModel, autoLoadList = true) => {
  const composition = useListViewModel(listVM, autoLoadList);

  const scrollHandler = () => {
    const boxes = Array.from(document.querySelectorAll(".box"));
    const el = boxes.pop();
    if (el) {
      const visible = isInViewport(el);
      if (
        visible &&
        !composition.listVMRef.isLoading &&
        composition.list.length < composition.pagination.total
      ) {
        composition.pagination.page += 1;
        const page = composition.pagination.page;
        // TODO: Perlu handle jika gagal fetch, kembalikan lagi page-nya (page--)
        composition.onPageChange(page);
      }
    }
  };

  onActivated(() => {
    // activate inifiteScrollList
    const boxContainer = document.getElementsByClassName("box-container");
    if (boxContainer.length > 0) {
      boxContainer[0].addEventListener("scroll", scrollHandler)
    } else {
      window.addEventListener("scroll", scrollHandler);
    }
  });

  onDeactivated(() => {
    // deactivate inifiteScrollList
    const boxContainer = document.getElementsByClassName("box-container");
    if (boxContainer.length > 0) {
      boxContainer[0].removeEventListener("scroll", scrollHandler)
    } else {
      window.removeEventListener("scroll", scrollHandler);
    }
  });

  return composition;
};

export default useInfiniteScrollList;
