import FormModel, { SaveContext } from "@/apps/core/models/formModel";
import router from "@/router";
import { Pengajuan, PengajuanAPI, PengajuanValidateAPI } from "./pengajuan";

export default class PengajuanValidateFM extends FormModel {
  productId: string;
  angsuran: number | null;

  constructor(productId: string, requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(new PengajuanValidateAPI(), new Pengajuan(), requiredFields, nonRequiredFields);
    this.productId = productId;
    this.angsuran = null;
  }

  getPayload(): Record<string, any> {
    const payload = super.getPayload();
    payload.produk = this.productId;
    return payload;
  }

  async save(
    context: SaveContext = SaveContext.Create,
    autoGoBack = true,
    commit = true,
    params: Record<string, any> = {}
  ): Promise<void> {
    try {
      let respData: Record<string, any>;
      params.commit = commit;
      if (context === SaveContext.Create) {
        respData = await this.api.create(this.getPayload(), params);
        if (!("error" in respData)) {
          const pengajuan = this.instance as Pengajuan;
          // jika sukses akan ada respData.id
          if (respData.id) pengajuan.id = respData.id;
          if (respData.angsuran) pengajuan.angsuran = respData.angsuran;
          if (respData.nilai && pengajuan.nilai !== respData.nilai) {
            pengajuan.nilai = respData.nilai;
          }
        }
      } else {
        const id = this.instance.id ?? "";
        respData = await this.api.update(id, this.getPayload(), params);
      }

      // penanda error http400, adalah adanya field error
      if ("error" in respData) {
        delete respData.error;
        // console.log(respData);
        this.updateErrorMap(respData);
      } else {
        // debugger;
        if (this.setRefreshRequest) {
          // request update listview
          this.setRefreshRequest(router.currentRoute.name ?? "");
        }
        if (autoGoBack) router.go(-1);
      }
    } catch (error) {
      /* do nothing */
    }
  }
}


class PengajuanFM extends FormModel {
  initValues: Record<string, any> | null;
  
  constructor(initValues: Record<string, any> | null, requiredFields: Array<string>, nonRequiredFields: Array<string>) {
    super(new PengajuanAPI(), new Pengajuan(), requiredFields, nonRequiredFields);
    this.initValues = initValues;
  }

  getPayload(): Record<string, any> {
    const payload = super.getPayload();
    if (this.initValues) {
      payload.produk = this.initValues.produk;
      payload.tenor = this.initValues.tenor;
      payload.nilai = this.initValues.nilai;
    } else {
      const pengajuan = this.instance as Pengajuan;
      const pinjamanJson = {
        // 'pengajuan': pengajuan.id,
        // 'id': pengajuan.pinjaman?.id,
        'produk': pengajuan.produk?.id, 
        'nilai_pinjaman': pengajuan.pinjaman?.nilaiPinjaman, 
        'bunga': pengajuan.pinjaman?.bunga, 'tenor': pengajuan.pinjaman?.tenor,
        'mulai': pengajuan.status === 'Setuju'? pengajuan.pinjaman?.mulai : null,
      }
      if (pengajuan?.produk?.nama === 'PINJAMAN DARURAT (PIJAT)') {
        const angsuran = pengajuan.pinjaman?.angsuran;
        Object.assign(pinjamanJson, {angsuran: angsuran});
        // console.log('angsuran');
        // console.log(angsuran.filter((item) => item.pokok > 0));
        // Object.assign(pinjamanJson, {angsuran: angsuran.filter((item) => item.pokok > 0)});
      }

      if (pengajuan?.pinjaman?.id === null) {
        Object.assign(pinjamanJson, {pengajuan: pengajuan.id});
      }
      payload['pinjaman'] = pinjamanJson;
    }
    return payload;
  }
}

export { PengajuanFM };
